
import img1 from "../praxis/images/gallerie1_600x400.png";
import img2 from "../praxis/images/gallerie2_600x400.png";
import img3 from "../praxis/images/gallerie3_600x400.png";
import img4 from "../praxis/images/gallerie4_600x400.png";
import img5 from "../praxis/images/gallerie5_600x400.png";
import img6 from "../praxis/images/gallerie6_600x400.png";
import img7 from "../praxis/images/gallerie7_600x400.png";
import img8 from "../praxis/images/gallerie8_600x400.png";
import img9 from "../praxis/images/gallerie9_600x400.png";
import img10 from "../praxis/images/gallerie10_600x400.png";
import img11 from "../praxis/images/gallerie11_600x400.png";
import img12 from "../praxis/images/gallerie12_600x400.png";
import img13 from "../praxis/images/gallerie13_600x400.png";
import img14 from "../praxis/images/gallerie14_600x400.png";
import img15 from "../praxis/images/gallerie15_600x400.png";
import img16 from "../praxis/images/gallerie16_600x400.png";
import img17 from "../praxis/images/gallerie17_600x400.png";
import img18 from "../praxis/images/gallerie18_600x400.png";
import img19 from "../praxis/images/gallerie19_600x400.png";
import img20 from "../praxis/images/gallerie20_600x400.png";
import img21 from "../praxis/images/gallerie21_600x400.png";

const imageExporter = () => {

    let data = [
        {
            "id": 1,
            "alt": "Empfangsbereich",
            "title": "Empfangsbereich",
            "image": img1,
            "type": "image/jpeg"
        },
        {
            "id": 2,
            "alt": "Untersuchung",
            "title": "Untersuchung",
            "image": img2,
            "type": "image/jpeg"
        },
        {
            "id": 3,
            "alt": "Wartezimmer Kinderecke",
            "title": "Wartezimmer Kinderecke",
            "image": img3,
            "type": "image/jpeg"
        },
        {
            "id": 4,
            "alt": "Empfang",
            "title": "Empfang",
            "image": img4,
            "type": "image/jpeg"
        },
        {
            "id": 5,
            "alt": "Wartezimmer Kinderecke",
            "title": "Wartezimmer Kinderecke",
            "image": img5,
            "type": "image/jpeg"
        },
        {
            "id": 6,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img6,
            "type": "image/jpeg"
        },
        {
            "id": 7,
            "alt": "Flur/Beratung",
            "title": "Flur/Beratung",
            "image": img7,
            "type": "image/jpeg"
        },
        {
            "id": 8,
            "alt": "Unsere Einheit",
            "title": "Unsere Einheit",
            "image": img8,
            "type": "image/jpeg"
        },
        {
            "id": 9,
            "alt": "Gaumenspange",
            "title": "Gaumenspange",
            "image": img9,
            "type": "image/jpeg"
        },
        {
            "id": 10,
            "alt": "Digitales Röntgengerät",
            "title": "Digitales Röntgengerät",
            "image": img10,
            "type": "image/jpeg"
        },
        {
            "id": 11,
            "alt": "Dental Modell",
            "title": "Dental Modell",
            "image": img11,
            "type": "image/jpeg"
        },
        {
            "id": 12,
            "alt": "Beratung",
            "title": "Beratung",
            "image": img12,
            "type": "image/jpeg"
        },
        {
            "id": 13,
            "alt": "Empfangsbereich",
            "title": "Empfangsbereich",
            "image": img13,
            "type": "image/jpeg"
        },
        {
            "id": 14,
            "alt": "Aligner",
            "title": "Aligner",
            "image": img14,
            "type": "image/jpeg"
        },
        {
            "id": 15,
            "alt": "Kieferorthopädische Praxis (Außenansicht)",
            "title": "Kieferorthopädische Praxis (Außenansicht)",
            "image": img15,
            "type": "image/jpeg"
        },
        {
            "id": 16,
            "alt": "Behandlungseinheit",
            "title": "Behandlungseinheit",
            "image": img16,
            "type": "image/jpeg"
        },
        {
            "id": 17,
            "alt": "Behandlungseinheit DKL",
            "title": "Behandlungseinheit DKL",
            "image": img17,
            "type": "image/jpeg"
        },
        {
            "id": 18,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img18,
            "type": "image/jpeg"
        },
        {
            "id": 19,
            "alt": "Wartezimmer",
            "title": "Wartezimmer",
            "image": img19,
            "type": "image/jpeg"
        },
        {
            "id": 20,
            "alt": "Visitenkarten",
            "title": "Visitenkarten",
            "image": img20,
            "type": "image/jpeg"
        },
        {
            "id": 21,
            "alt": "Intraoralscanner",
            "title": "Intraoralscanner",
            "image": img21,
            "type": "image/jpeg"
        }
    ]

    return (
        <>
            {data.map((index) => (
                <div className="embla__slide" key={index}>
                    <div className="embla__parallax">
                        <div className="embla__parallax__layer">
                            <img src={index.image} alt={index.alt} loading="lazy" title={index.title} style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default imageExporter