import React from "react";
import "./praxis.css";
import "./embla.css"
import EmblaCarousel from "./emblaCarousel"


const OPTIONS = { dragFree: true, loop: true }
const SLIDE_COUNT = 5
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

const Praxis = () => {

    return (
        <>
            <div className="praxis_headline">
                <div className="praxis__headline">
                    <h1> Einblicke in unsere KFO-Praxis </h1>
                </div>
            </div>

            <div className="praxis__intro">
                <div className="praxis___intro">
                    <div className="praxis____intro">
                        <p>
                            Unsere Praxis verfügt über modernste technische Ausstattungen.
                            Mit unserer digitalen Röntgenanlage haben wir optimale Diagnosemöglichkeiten
                            bei minimaler Strahlenbelastung. Mittels unseres Intraoralscanners ist es oft möglich,
                            ganz auf konventionelle Abdrücke mit herkömmlicher Abdruckmasse zu verzichten.
                        </p>
                    </div>
                </div>
            </div>

            <EmblaCarousel slides={SLIDES} options={OPTIONS} />

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Termine können einfach und schnell auch online gebucht werden <br></br>

                        <a href="https://iie-systems.de/online-termin-kfo-medefindt" target="_blank" rel="noreferrer">
                            <button title="onlineAppointments" className="praxis_btn">
                                Termin online buchen
                            </button>
                        </a>

                    </p>
                </div>
            </div >
        </>
    )
}

export default Praxis